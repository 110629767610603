import { $t } from '@/i18n';
import { formatDate, formatHours } from '@/composables/useDateFormat';
import type {
  PrintedLabel,
  zplOrderDto,
  ZplTemplateStaticTranslations,
} from '../types';
import { MAXIMUM_LAST_NAME_LENGTH } from '../types';
import { getQrData } from '../helpers/printLabel';
import { getDynamicFontSize } from '@/utils/helpers/getDynamicFontSize';

export class ZplTemplateService {
  private zplTemplateWrapper = (content: string) => `
    ^XA${content}^XZ
  `;

  private translations: ZplTemplateStaticTranslations = {
    curbside: $t(
      'pages.label-print-label-printer.list-item-service-type-curbside.text',
    ),
  };

  private zplTemplateStaticPart = (
    pickupCode: string,
    lastName: string,
    orderReference: string,
    orderTime: string,
    orderDate: string,
    translations: ZplTemplateStaticTranslations,
    offset = 0,
  ): string => {
    let formattedLastName = lastName;
    if (lastName.length > MAXIMUM_LAST_NAME_LENGTH) {
      formattedLastName = `${lastName.slice(0, MAXIMUM_LAST_NAME_LENGTH)}...`;
    }

    const lastNameFontSize = getDynamicFontSize(
      lastName,
      90,
      60,
      15,
      MAXIMUM_LAST_NAME_LENGTH,
    );

    return `^FO0,75^A0,110^FB800,1,0,C^FD${pickupCode}\\&^FS
      ^FO0,200^A0,${lastNameFontSize}^FB800,1,0,C^FD${formattedLastName}\\&^FS
      ^FO0,900^A0,62^FB800,1,0,C^FD${orderTime}\\&^FS
      ^FO0,970^A0,30^FB800,1,0,C^FD${orderDate}\\&^FS
      ^FO50,1050^A0,30^FDBags:^FS
      ^FO50,${1100 + offset}^GB700,3,3^FS
      ^FO50,${1150 + offset}^A0,30^FDOrder-Ref.:^FS
      ^FO300,${1150 + offset}^A0,40^FD${orderReference}^FS`;
  };

  private zplTemplateDynamicPart = (
    label: PrintedLabel,
    bagNumberOfTotal: number,
    totalLabels: number,
    offset = 0,
  ): string => {
    const qrData = getQrData(label);

    return `^FO210,380^BQN,2,10^FVQA,${qrData}^FS
      ^FO300,${
        1050 + offset
      }^A0,40^FV${bagNumberOfTotal} of ${totalLabels} Total^FS
      ${bagNumberOfTotal === totalLabels ? '^MCY' : '^MCN'}`;
  };

  getZebraPrinterLabels = (
    orderData: zplOrderDto,
    labels: PrintedLabel[],
    isCustomerLastNameVisible = false,
  ): string => {
    return labels
      .map((label: PrintedLabel, index: number) => {
        let content = '';
        if (index === 0) {
          content += this.zplTemplateStaticPart(
            orderData.pickupCode,
            isCustomerLastNameVisible ? orderData.customer.lastName : '',
            orderData.orderReference,
            formatHours(new Date(String(orderData.startTime)), true),
            formatDate(new Date(String(orderData.startTime))),
            this.translations,
            0,
          );
        }

        content += this.zplTemplateDynamicPart(
          label,
          index + 1,
          labels.length,
          0,
        );

        return this.zplTemplateWrapper(content);
      })
      .join('');
  };
}
